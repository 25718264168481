<template>
  <div class="view-root">
    <div class="view-content apply-container">
      <div style="width: 100%;max-width: 1360px;background-color: white;margin: 0 auto;height: 100vh;">
        <div class="data-content-show">
          <div class="data-show">
            <p style="color:  #FE7702;font-size: 24px;font-weight: bold">School Apply Form</p>
            <p style="color: #FE7702;font-size: 24px;text-align: center;margin-top: 50px">FinSports</p>
            <p style="color: #FE7702;font-size: 24px;text-align: center;margin-top: 10px;font-weight: bold">Registration
              Form</p>
            <p style="color: #583518;font-size: 18px;line-height: 40px;margin-top: 30px;">
              FinSports targets to bring the excitement, fun and spirits of physical sports to the financial investment
              sector. By providing
              a standardized sporting mechanism in finance, FinSports encourages the young of today to learn financial
              skills and
              compete in a dynamic way.At FinSports, we build competing platforms for finance and organize FinSports
              meetings at
              different levels across the world.
            </p>
            <el-form ref="form" :model="form" :rules="formRules" style="margin-top: 40px">

              <div class="flex-row">
                <div style="flex: 1">
                  <el-form-item label="Country" prop="country">
                    <div class="input">
                      <el-input v-model="form.country" placeholder="Please input"></el-input>
                    </div>
                  </el-form-item>
                </div>
                <div style="width: 120px"></div>
                <div style="flex: 1">
                  <el-form-item label=" Curriculum type" prop="curriculum_type">
                    <div class="input">
                      <el-input v-model="form.curriculum_type" placeholder="Please input"></el-input>
                    </div>
                  </el-form-item>
                </div>
              </div>

              <div class="flex-row">
                <div style="flex: 1">
                  <el-form-item label=" School Name in English" prop="school_name_eng">
                    <div class="input">
                      <el-input v-model="form.school_name_eng" placeholder="Please input"></el-input>
                    </div>
                  </el-form-item>
                </div>
                <div style="width: 120px"></div>
                <div style="flex: 1">
                  <el-form-item label="  School Name in Native Language" prop="school_name_in_native_language">
                    <div class="input">
                      <el-input v-model="form.school_name_in_native_language" placeholder="Please input"></el-input>
                    </div>
                  </el-form-item>
                </div>
              </div>

              <div class="flex-row">
                <div style="flex: 1">
                  <el-form-item label=" Point of Contact / Program Adviser" prop="point_of_contact_or_program_adviser">
                    <div class="input">
                      <el-input v-model="form.point_of_contact_or_program_adviser"
                                placeholder="Please input"></el-input>
                    </div>
                  </el-form-item>
                </div>
                <div style="width: 120px"></div>
                <div style="flex: 1">
                  <el-form-item label="Name" prop="name">
                    <div class="input">
                      <el-input v-model="form.name" placeholder="Please input"></el-input>
                    </div>
                  </el-form-item>
                </div>
              </div>

              <div class="flex-row">
                <div style="flex: 1">
                  <el-form-item label="Title" prop="title">
                    <div class="input">
                      <el-input v-model="form.title" placeholder="Please input"></el-input>
                    </div>
                  </el-form-item>
                </div>
                <div style="width: 120px"></div>
                <div style="flex: 1">
                  <el-form-item label=" Email Address" prop="email">
                    <div class="input">
                      <el-input v-model="form.email" placeholder="Please input"></el-input>
                    </div>
                  </el-form-item>
                </div>
              </div>

              <div class="flex-row">
                <div style="flex: 1">
                  <el-form-item label=" Phone Number" prop="phone">
                    <div class="input">
                      <el-input v-model="form.phone" placeholder="Please input"></el-input>
                    </div>
                  </el-form-item>
                </div>
                <div style="width: 120px"></div>
                <div style="flex: 1">
                  <el-form-item label="  IM Number (please specify which IM platform)" prop="im_number">
                    <div class="input">
                      <el-input v-model="form.im_number" placeholder="Please input"></el-input>
                    </div>
                  </el-form-item>
                </div>
              </div>

            </el-form>
            <p class="btn_submit" @click="submitData">Submit</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {saveAppForm} from '../api/sports'
import '../assets/common/common.css'

export default {
  name: "appapply",
  data() {
    return {
      form: {
        country: '',
        curriculum_type: '',
        school_name_eng: '',
        school_name_in_native_language: '',
        point_of_contact_or_program_adviser: '',
        name: '',
        title: '',
        email: '',
        phone: '',
        im_number: ''
      },
      formRules: {
        country: [
          {required: true, message: 'Please input', trigger: 'change'}
        ],
        curriculum_type: [
          {required: true, message: 'Please input', trigger: 'change'}
        ],
        school_name_eng: [
          {required: true, message: 'Please input', trigger: 'change'}
        ],
        school_name_in_native_language: [
          {required: true, message: 'Please input', trigger: 'change'}
        ],
        point_of_contact_or_program_adviser: [
          {required: true, message: 'Please input', trigger: 'change'}
        ],
        name: [
          {required: true, message: 'Please input', trigger: 'change'}
        ],
        title: [
          {required: true, message: 'Please input', trigger: 'change'}
        ],
        email: [
          {required: true, message: 'Please input', trigger: 'change'}
        ],
        phone: [
          {required: true, message: 'Please input', trigger: 'change'}
        ],
        im_number: [
          {required: true, message: 'Please input', trigger: 'change'}
        ],
      }
    }
  },
  mounted() {
    window.document.title = 'FinSports-学校申请表'
  },
  methods: {
    submitData() {
      this.$refs.form.validate((val) => {
        if (val) {
          saveAppForm(this.form).then(res => {
            if (res.data) {
              this.$message.success('Submit successfully')
            }
          })
        }
      })
    },
  }
}
</script>

<style scoped lang="less">

.apply-container {
  width: 100%;
  height: 100vh;
  background-image: url("../assets/root_bg.png");
  background-size: cover;
  background-position: 100%;
  background-color: transparent;
  overflow-y: scroll;
}

.apply-data-container {
  background-color: white;
  max-width: 1360px;
  background-color: white;
  display: flex;
  flex-direction: column;
  margin: 0px auto;

  .data-show {
    padding: 40px;
  }
}

.btn_submit {
  margin-top: 30px;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  width: 160px;
  height: 40px;
  line-height: 40px;
  background: #FE7702;
  border-radius: 2px 2px 2px 2px;
  font-size: 16px;
  font-weight: normal;
  color: #FFFFFF;
}
</style>
